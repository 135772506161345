import { sessionStorageUtils } from "lib/utils/storageUtils";
import { trackClick, trackForm } from "../../scripts/segment";
import { getUtmData } from "./utm_utils";
import { underwritingStatus } from "constant/inschoolStages";

const utmDetails = getUtmData(null);

export const productType = {
  INSCHOOL: "IS",
  BANK: "BA-CC",
};

export const pageName = {
  REFI_CITIZENSHIP: "Refi Citizenship",
  REFI_US_CITIZEN_FORM: "Refi US Citizen Form",
  REFI_LANDING_PAGE: "Refi Landing Page",
  LOGIN_PAGE: "Login Page",
  REFI_US_APPLICATION_CONFIRMATION: "Refi US Application Confirmation",
  HOME_PAGE: "Home Page",
  ABOUT_US_PAGE: "About us",
  INSCHOOL_LOAN_FEATURES_US_PAGE: "IS Loan Features US",
  INSCHOOL_HOW_IT_WORKS_US_PAGE: "IS How it works US",
  INSCHOOL_FAQ_PAGE: "IS FAQ",
  EDUCATION_LOAN_FOR_MS_PAGE: "Education Loan for MS",
  IS_START_APPLICATION: "IS Start Application",
  IS_DASHBOARD: "IS Dashboard",
  IS_UNIVERSITY_AND_COURSE_DETAILS: "IS University and Course Details",
  IS_PERSONAL_DETAILS: "IS Personal Details",
  IS_ACADEMIC_DETAILS: "IS Academic Details",
  IS_COSIGNER_DETAILS: "IS Cosigner Details",
  IS_LOAN_OFFER_DETAILS: "IS Loan Offer Details",
  IS_MMP: "IS MMP",
  IS_ADDRESS_DETAILS: "IS Address Details",
  IS_APPLICANT_DOCUMENTS: "IS Applicant Documents",
  IS_COSIGNER_DOCUMENTS: "IS Cosigner Documents",
  IS_LOAN_OFFER_CONFIRMATION: "IS Loan Offer Confirmation",
  IS_LOAN_OFFER_APPROVED: "IS Loan Offer Approved",
  IS_SANCTION_FEE: "IS Sanction Fee",
  IS_SF_PAYMENT_FAILED: "IS SF Payment Failed",
  IS_SF_PAID: "Is SF Paid",
  IS_PF_PAYMENT_FAILED: "IS PF Payment Failed",
  IS_SANCTION_LETTER: "IS Sanction Letter",
  IS_PF_PENDING: "IS PF Pending",
  IS_PF_PAYEE_DETAILS: "IS PF Payee Details ",
  IS_PF_TRANSACTION_SUMMARY: "IS PF Transaction Summary",
  IS_PF_PAYMENT_OPTIONS: "IS PF Payment Options",
  IS_PF_PAID: "IS PF Paid",
  IS_UPLOAD_I20_AND_VISA: "IS Upload i20 & Visa",
  IS_SIGN_AGREEMENT: "IS Sign Agreement",
  IS_OPEN_BANK_ACCOUNT: "IS Open Bank Account",
  IS_BANK_ACCOUNT_PENDING: "IS Bank Account Pending",
  IS_CONTRACT_REVIEW: "IS Contract Review",
  IS_CONTRACT_APPROVED: "IS Contract Approved",
  IS_ACH_PENDING: "IS ACH Pending",
  IS_ACH_REVIEW: "IS ACH Review",
  IS_REQUEST_FUNDS: "IS Request Funds",
  IS_CREDIT_REJECTED: "IS Credit Rejected",
  IS_ADD_EXTRA_COSIGNER: "IS Add extra Cosigner",
  IS_COURSE_REJECTED: "IS Course Rejected",
  IS_EXTRA_CHARGE_COLLECTION: "IS Extra Charge Collection",
  BA_DASHBOARD: "BA Dashboard",
  BA_START_APPLICATION: "BA Start Application",
  BA_PERSONAL_DETAILS: "BA Personal Details",
  BA_DOCUMENTS_UPLOAD: "BA Documents Upload",
  BA_DISCLOSURE: "BA Disclosure",
  BA_APPLICATION_COMPLETE: "BA Application Complete",
  BA_HELD_BY_SALES: "BA Held By Sales",
};

export const leadState = {
  LOGGED_IN: "LOGGED_IN",
  US_CITIZEN: "US_CITIZEN",
  LEAD_CAPTURE: "LEAD_CAPTURE",
  COURSE_DETAILS: "COURSE_DETAILS",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  PROFESSIONAL_DETAILS: "PROFESSIONAL_DETAILS",
  COSIGNER_DETAILS: "COSIGNER_DETAILS",
  OFFER_DETAILS: "OFFER_DETAILS",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  ADDRESS_DETAILS: "ADDRESS_DETAILS",
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  COSIGNER_DOCUMENTS_UPLOAD: "COSIGNER_DOCUMENTS_UPLOAD",
  OFFER_CONFIRMATION: "OFFER_CONFIRMATION",
  SENT_TO_CREDIT: "SENT_TO_CREDIT",
  CREDIT_REJECTED: "CREDIT_REJECTED",
  FINAL_NEGOTIATED_OFFER: "FINAL_NEGOTIATED_OFFER",
  SANCTION_GENERATED: "SANCTION_GENERATED",
  PF_PENDING: "PF_PENDING",
  PF_PAID: "PF_PAID",
  CONTRACT_PENDING: "CONTRACT_PENDING",
  CONTRACT_REVIEW: "CONTRACT_REVIEW",
  BANK_ACCOUNT_PENDING: "BANK_ACCOUNT_PENDING",
  ACH_PENDING: "ACH_PENDING",
  ACH_REVIEW: "ACH_REVIEW",
  OK_TO_DISBURSE: "OK_TO_DISBURSE",
};

export const eventName = {
  IS_COURSE_ACCEPTED: "Inschool Course Accepted",
  IS_LOAN_OFFER_ACCEPTED: "Inschool Loan Offer Accepted",
  IS_OFFER_CONFIRMATION: "Inschool Offer Confirmation",
  IS_PROFESSIONAL_DETAILS_FILLED: "Inschool Professional Details Filled",
};

export const getEventPayload = (props) => {
  const referrer = sessionStorageUtils?.getItem("prevPath") || "";
  const commonProperties = {
    product: props?.product || "NA",
    appId: props?.applicationId || NaN,
    pageName: props?.pageName || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    referrer: referrer === "null" ? document?.referrer : referrer,
    formId: props?.formId || "NA",
  };
  switch (props.pageName) {
    case pageName.REFI_US_CITIZEN_FORM:
      return {
        ...commonProperties,
        university: props?.universityName || "NA",
        campus: props?.campusName || "NA",
        course: props?.courseName || "NA",
        loanAmount: props?.loanAmount,
      };
    case pageName.IS_UNIVERSITY_AND_COURSE_DETAILS:
      return {
        ...commonProperties,
        university: props?.university || "NA",
        campus: props?.campus || "NA",
        course: props?.course || "NA",
      };
    case pageName.IS_COSIGNER_DETAILS:
      if (props?.cosignerAttempt === 1) {
        return {
          ...commonProperties,
          cosignerStatus: props?.cosignerStatus || "NA",
          finalUEResult: props?.finalUEResult || "NA",
          cosignerAttempt: props?.cosignerAttempt || NaN,
          cosigner1FirstName: props?.cosigner1FirstName || "NA",
          cosigner1Relationship: props?.cosigner1Relationship || "NA",
          cosigner1PhoneNumber: props?.cosigner1PhoneNumber || "NA",
        };
      } else if (props?.cosignerAttempt === 2) {
        return {
          ...commonProperties,
          cosignerStatus: props?.cosignerStatus || "NA",
          finalUEResult: props?.finalUEResult || "NA",
          cosignerAttempt: props?.cosignerAttempt || NaN,
          cosigner2FirstName: props?.cosigner2FirstName || "NA",
          cosigner2Relationship: props?.cosigner2Relationship || "NA",
          cosigner2PhoneNumber: props?.cosigner2PhoneNumber || "NA",
        };
      }
    case pageName.IS_LOAN_OFFER_DETAILS:
      return {
        ...commonProperties,
        offeredROI: props?.offeredROI,
        offeredLoanAmount: props?.offeredLoanAmount,
        tenure: props?.tenure,
      };
    case pageName.IS_MMP:
      return {
        ...commonProperties,
        mmpAmount: props?.mmpAmount * 10,
      };
    case pageName.IS_PF_PAYMENT_OPTIONS:
      return {
        ...commonProperties,
        paymentMethod: props?.paymentMethod || "NA",
      };
    default:
      return commonProperties;
  }
};

export const getUserPayload = (props) => {
  const commonProperties = {
    leadState: props?.leadState || "NA",
  };
  switch (props.pageName) {
    case pageName.REFI_CITIZENSHIP:
      return {
        ...commonProperties,
        citizenship: props?.citizenship || "NA",
      };
    case pageName.REFI_US_CITIZEN_FORM:
      return {
        ...commonProperties,
        email: props?.email,
        country: props?.country || "NA",
        firstName: props?.firstName,
        lastName: props?.lastName,
        gender: props?.gender === "M" ? "male" : "female" || "NA",
        birthDate: props?.dob,
      };
    case pageName.IS_UNIVERSITY_AND_COURSE_DETAILS:
      return {
        ...commonProperties,
        country: props?.country || "NA",
        email: props?.email,
      };
    case pageName.IS_PERSONAL_DETAILS:
      return {
        ...commonProperties,
        email: props?.email,
        firstName: props?.firstName,
        lastName: props?.lastName,
        middleName: props?.middleName,
        gender: props?.gender === "M" ? "male" : "female" || "NA",
        birthDate: props?.dob,
      };
    default:
      return commonProperties;
  }
};

export const getPageName = (page) => {
  switch (page) {
    case "COURSE_DETAILS":
      return pageName.IS_UNIVERSITY_AND_COURSE_DETAILS;
    case "PERSONAL_DETAILS":
      return pageName.IS_PERSONAL_DETAILS;
    case "PROFESSIONAL_DETAILS":
      return pageName.IS_ACADEMIC_DETAILS;
    case "COSIGNER_DETAILS":
      return pageName.IS_COSIGNER_DETAILS;
    case "OFFER_DETAILS":
      return pageName.IS_LOAN_OFFER_DETAILS;
    case "PAYMENT_DETAILS":
      return pageName.IS_MMP;
    case "ADDRESS_DETAILS":
      return pageName.IS_ADDRESS_DETAILS;
    case "DOCUMENTS_UPLOAD":
      return pageName.IS_APPLICANT_DOCUMENTS;
    case "COSIGNER_DOCUMENTS_UPLOAD":
      return pageName.IS_COSIGNER_DOCUMENTS;
    case "PF_PENDING":
      return pageName.IS_PF_PENDING;
    case "POST_SANCTION_DOC_PENDING":
      return pageName.IS_UPLOAD_I20_AND_VISA;
    case "CONTRACT_PENDING":
      return pageName.IS_SIGN_AGREEMENT;
    default:
      return "applications";
  }
};
export const generatePageName = (isLink = false) => {
  if (process.browser) {
    const path = window?.location?.pathname || "";
    let screenStage;
    let referrer;
    if (isLink) {
      referrer = sessionStorageUtils?.getItem("prevPath") || "";
      screenStage = referrer
        ?.split("/")
        .slice(-1)
        .pop()
        ?.toUpperCase();
    } else {
      screenStage = path
        .split("/")
        .slice(-1)
        .pop()
        ?.toUpperCase();
    }
    switch (screenStage) {
      case "COURSE_DETAILS":
        return pageName.IS_UNIVERSITY_AND_COURSE_DETAILS;
      case "PERSONAL_DETAILS":
        return pageName.IS_PERSONAL_DETAILS;
      case "PROFESSIONAL_DETAILS":
        return pageName.IS_ACADEMIC_DETAILS;
      case "COSIGNER_DETAILS":
        return pageName.IS_COSIGNER_DETAILS;
      case "OFFER_DETAILS":
        return pageName.IS_LOAN_OFFER_DETAILS;
      case "PAYMENT_DETAILS":
        return pageName.IS_MMP;
      case "ADDRESS_DETAILS":
        return pageName.IS_ADDRESS_DETAILS;
      case "DOCUMENTS_UPLOAD":
        return pageName.IS_APPLICANT_DOCUMENTS;
      case "COSIGNER_DOCUMENTS_UPLOAD":
        return pageName.IS_COSIGNER_DOCUMENTS;
      case "OFFER_CONFIRMATION":
        return pageName.IS_LOAN_OFFER_CONFIRMATION;
      case "PF_PENDING":
        return pageName.IS_PF_PENDING;
      case "POST_SANCTION_DOC_PENDING":
        return pageName.IS_UPLOAD_I20_AND_VISA;
      case "CONTRACT_PENDING":
        return pageName.IS_SIGN_AGREEMENT;
      case "CONTRACT_REVIEW":
        return pageName.IS_CONTRACT_REVIEW;
      case "CREDIT_REJECTED":
        return pageName.IS_CREDIT_REJECTED;
      case "SANCTION_GENERATED":
        return pageName.IS_SANCTION_LETTER;
      case "":
        return pageName.HOME_PAGE;
      case "ABOUT":
        return pageName.ABOUT_US_PAGE;
      case "USA":
        const tempPath = isLink ? referrer : path;
        if (tempPath.split("/").slice(-2, -1)[0] === "how-it-works") {
          return pageName.INSCHOOL_HOW_IT_WORKS_US_PAGE;
        } else if (tempPath.split("/").slice(-2, -1)[0] === "loan-features") {
          return pageName.INSCHOOL_LOAN_FEATURES_US_PAGE;
        }
      case "FAQ":
        return pageName.INSCHOOL_FAQ_PAGE;
      case "EDUCATION-LOAN-FOR-MS-IN-USA":
        return pageName.EDUCATION_LOAN_FOR_MS_PAGE;
      case "PERSONAL_INFO":
        return pageName.BA_PERSONAL_DETAILS;
      case "DOCUMENTS":
        return pageName.BA_DOCUMENTS_UPLOAD;
      case "HELD_BY_SALES":
        return pageName.BA_HELD_BY_SALES;
      case "APPLICATION_COMPLETE":
        return pageName.BA_APPLICATION_COMPLETE;
      default:
        return pageName.HOME_PAGE;
    }
  }
  return null;
};

export const getFormId = getPageName;
export const generateFormId = generatePageName;

export const triggerLinkClick = (id) => {
  switch (+id) {
    case 1:
      trackClick(generatePageName(true), {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "About Us",
        contentFormat: "Link",
      });
      break;
    case 2:
      trackClick(generatePageName(true), {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "Loan Features",
        contentFormat: "Link",
      });
      break;
    case 3:
      trackClick(generatePageName(true), {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "How it works",
        contentFormat: "Link",
      });
      break;
    case 4:
      trackClick(generatePageName(true), {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "FAQ's",
        contentFormat: "Link",
      });
      break;
    case 5:
      trackClick(generatePageName(true), {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "Education Loan for MS",
        contentFormat: "Link",
      });
      break;
    case 6:
      trackClick(generatePageName(true), {
        widgetName: "Application",
        widgetFormat: "Banner",
        contentName: "New Application",
        contentFormat: "Button",
      });
      break;
    case 7:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Home",
        contentFormat: "Link",
      });
      break;
    case 8:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "About Us",
        contentFormat: "Link",
      });
      break;
    case 9:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Loan Features",
        contentFormat: "Link",
      });
      break;
    case 10:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "How It Works",
        contentFormat: "Link",
      });
      break;
    case 11:
      trackClick(generatePageName(true), {
        widgetName: "Footer-Education Loan",
        widgetFormat: "Banner",
        contentName: "FAQ's",
        contentFormat: "Link",
      });
      break;
    case 12:
      trackClick(generatePageName(true), {
        widgetName: "Footer-US Credit Card",
        widgetFormat: "Banner",
        contentName: "FAQs",
        contentFormat: "Link",
      });
      break;
    case 13:
      trackClick(generatePageName(true), {
        widgetName: "Footer-Legal",
        widgetFormat: "Banner",
        contentName: "Privacy Policy",
        contentFormat: "Link",
      });
      break;
    case 14:
      trackClick(generatePageName(true), {
        widgetName: "Footer-Legal",
        widgetFormat: "Banner",
        contentName: "Terms & Conditions",
        contentFormat: "Link",
      });
      break;
    case 15:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Education Loan Eligibility",
        contentFormat: "Link",
      });
      break;
    case 16:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Education Loan Interest",
        contentFormat: "Link",
      });
      break;
    case 17:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Education Loan Calculator",
        contentFormat: "Link",
      });
      break;
    case 18:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Education Loan Without Collateral",
        contentFormat: "Link",
      });
      break;
    case 19:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Education Loan Documents",
        contentFormat: "Link",
      });
      break;
    case 20:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Education Loan Tax Benefits",
        contentFormat: "Link",
      });
      break;
    case 21:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "How to apply for Education Loan",
        contentFormat: "Link",
      });
      break;
    case 22:
      trackClick(generatePageName(true), {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Study Abroad",
        contentFormat: "Link",
      });
      break;
    case 23:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: "Application",
        widgetFormat: "Banner",
        contentName: "New Application",
        contentFormat: "Button",
      });
      break;
    case 24:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Hero",
        widgetFormat: "Banner",
        contentName: "USA Education Loan",
        contentFormat: "Link",
      });
      break;
    case 25:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: "IS Get Sanction Letter",
        widgetFormat: "Button",
        contentName: "Get Sanction Letter",
        contentFormat: "Button",
      });
      break;
    case 26:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: pageName.IS_DASHBOARD,
        widgetFormat: "Button",
        contentName: "Request Funds",
        contentFormat: "Button",
      });
      break;
    case 27:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: "IS Add Another Cosigner",
        widgetFormat: "Banner",
        contentName: "Add another cosigner",
        contentFormat: "Button",
      });
      break;
    case 28:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: pageName.IS_DASHBOARD,
        widgetFormat: "Button",
        contentName: "Upload i20 & Visa",
        contentFormat: "Button",
      });
      break;
    case 29:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: "Application",
        widgetFormat: "Banner",
        contentName: "View Application",
        contentFormat: "Button",
      });
      break;
    case 30:
      trackClick(pageName.IS_DASHBOARD, {
        widgetName: "Application",
        widgetFormat: "Banner",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 31:
      trackClick(pageName.IS_SIGN_AGREEMENT, {
        widgetName: "IS Provisional Loan Agreement Emailed",
        widgetFormat: "Button",
        contentName: "Continue",
        contentFormat: "Button",
      });
      break;
    case 32:
      trackClick(pageName.IS_LOAN_OFFER_DETAILS, {
        widgetName: pageName.IS_LOAN_OFFER_DETAILS,
        widgetFormat: "Form",
        contentName: "Save and continue",
        contentFormat: "Button",
      });
      break;
    case 33:
      trackClick(pageName.IS_PERSONAL_DETAILS, {
        widgetName: "IS Well Done",
        widgetFormat: "Banner",
        contentName: "Continue",
        contentFormat: "Button",
      });
      break;
    case 34:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 35:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Header",
        widgetFormat: "Banner",
        contentName: "Resume",
        contentFormat: "Button",
      });
      break;
    case 36:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Hero",
        widgetFormat: "Banner",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 37:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Your best way into the US",
        widgetFormat: "Banner",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 38:
      trackClick(pageName.EDUCATION_LOAN_FOR_MS_PAGE, {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Get Loan Offer",
        contentFormat: "Button",
      });
      break;
    case 39:
      trackClick(pageName.INSCHOOL_LOAN_FEATURES_US_PAGE, {
        widgetName: "Eligibility Section",
        widgetFormat: "Banner",
        contentName: "Get Loan Offer",
        contentFormat: "Button",
      });
      break;
    case 40:
      trackClick(pageName.INSCHOOL_LOAN_FEATURES_US_PAGE, {
        widgetName: "Hero",
        widgetFormat: "Banner",
        contentName: "Get Loan Offer",
        contentFormat: "Button",
      });
      break;
    case 41:
      trackClick(pageName.ABOUT_US_PAGE, {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 42:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Footer",
        widgetFormat: "Banner",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 43:
      trackClick(pageName.INSCHOOL_HOW_IT_WORKS_US_PAGE, {
        widgetName: "Disbursements and Payments",
        widgetFormat: "How it works",
        contentName: "Resume Application",
        contentFormat: "Button",
      });
      break;
    case 44:
      trackClick(generatePageName(true), {
        widgetName: "Homepage-Modal",
        widgetFormat: "Modal",
        contentName: "Leap's Education Loan",
        contentFormat: "Button",
      });
      break;
    case 45:
      trackClick(generatePageName(true), {
        widgetName: "Homepage-Modal",
        widgetFormat: "Modal",
        contentName: "Leap's US Bank Account",
        contentFormat: "Button",
      });
      break;
    case 46:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Our Products",
        widgetFormat: "Banner",
        contentName: "Get Loan Offer",
        contentFormat: "Button",
      });
      break;
    case 47:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Our Products",
        widgetFormat: "Banner",
        contentName: "Get US Bank Account",
        contentFormat: "Button",
      });
      break;
    case 48:
      trackClick(pageName.HOME_PAGE, {
        widgetName: "Our Products-Education Loan",
        widgetFormat: "Banner",
        contentName: "Learn More",
        contentFormat: "Link",
      });
      break;
    case 49:
      trackClick(generatePageName(true), {
        widgetName: "Footer-Legal",
        widgetFormat: "Banner",
        contentName: "SOFR Details",
        contentFormat: "Link",
      });
      break;
    default:
      trackClick(generatePageName(true), {});
  }
};

export const isCosignerRejected = (underwriting) =>
  underwriting === underwritingStatus.COSIGNER_REJECTED;

export const getFinalUEResult = (underwritingStatus, cosignerAttempt) => {
  const isCosignerReject = isCosignerRejected(underwritingStatus);
  if (isCosignerReject && cosignerAttempt === 1) {
    return "SECOND_COSIGNER_NEEDED";
  } else if (isCosignerReject) {
    return "REJECTED";
  } else {
    return underwritingStatus;
  }
};

export const triggerInschoolTrackForm = ({
  values,
  applicationId,
  screen,
  nextStage,
  underwriting,
  cosignerAttempt,
  loanInterest,
  loanAmountInUSD,
}) => {
  trackForm("Form Filled", {
    ...values,
    ...values?.profile,
    product: productType.INSCHOOL,
    applicationId,
    pageName: getPageName(screen),
    formId: getFormId(screen),
    leadState: nextStage || screen,
    cosigner1FirstName: cosignerAttempt === 1 && values?.firstName,
    cosigner1Relationship: cosignerAttempt === 1 && values?.relationship,
    cosigner1PhoneNumber: cosignerAttempt === 1 && values?.phone,
    cosigner2FirstName: cosignerAttempt === 2 && values?.firstName,
    cosigner2Relationship: cosignerAttempt === 2 && values?.relationship,
    cosigner2PhoneNumber: cosignerAttempt === 2 && values?.phone,
    cosignerAttempt,
    finalUEResult: getFinalUEResult(underwriting, cosignerAttempt),
    cosignerStatus: isCosignerRejected(underwriting)
      ? "REJECTED"
      : underwriting,
    offeredROI: loanInterest,
    offeredLoanAmount: loanAmountInUSD,
    tenure: values?.tenureInMonths,
    mmpAmount: values?.minimumMonthlyPayments,
  });
};
