export const inschoolStages = {
  COURSE_DETAILS: "COURSE_DETAILS",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  PROFESSIONAL_DETAILS: "PROFESSIONAL_DETAILS",
  COSIGNER_DETAILS: "COSIGNER_DETAILS",
  OFFER_DETAILS: "OFFER_DETAILS",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  ADDRESS_DETAILS: "ADDRESS_DETAILS",
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  EXTRA_COSIGNER_DETAILS: "EXTRA_COSIGNER_DETAILS",
  COSIGNER_DOCUMENTS_UPLOAD: "COSIGNER_DOCUMENTS_UPLOAD",
  OFFER_CONFIRMATION: "OFFER_CONFIRMATION",
  SENT_TO_CREDIT: "SENT_TO_CREDIT",
  SANCTION_GENERATED: "SANCTION_GENERATED",
  EXTRA_CHARGE_COLLECTION: "EXTRA_CHARGE_COLLECTION",
  CREDIT_REJECTED: "CREDIT_REJECTED",
  HELD_BY_CREDIT: "HELD_BY_CREDIT",
  POST_SANCTION_DOC_PENDING: "POST_SANCTION_DOC_PENDING",
  CONTRACT_PENDING: "CONTRACT_PENDING",
  PF_PENDING: "PF_PENDING",
  PF_PAID: "PF_PAID",
  POST_PF_NEGOTIATION: "POST_PF_NEGOTIATION",
  CONTRACT_REVIEW: "CONTRACT_REVIEW",
  CONTRACT_APPROVED: "CONTRACT_APPROVED",
  OK_TO_DISBURSE: "OK_TO_DISBURSE",
};

export const inschoolSubStages = {
  ADD_EXTRA_COSIGNER: "ADD_EXTRA_COSIGNER",
  ATTACH_EXTRA_DOCUMENT: "ATTACH_EXTRA_DOCUMENT",
};

export const underwritingStatus = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COSIGNER_REJECTED: "COSIGNER_REJECTED",
  COSIGNER_NEEDED: "COSIGNER_NEEDED",
};

export const applicationStatusTypes = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const inschoolStagesPostOfferConfirmation = [
  inschoolStages.OFFER_CONFIRMATION,
  inschoolStages.SENT_TO_CREDIT,
  inschoolStages.SANCTION_GENERATED,
  inschoolStages.HELD_BY_CREDIT,
  inschoolStages.POST_SANCTION_DOC_PENDING,
  inschoolStages.CONTRACT_PENDING,
  inschoolStages.PF_PENDING,
  inschoolStages.PF_PAID,
  inschoolStages.POST_PF_NEGOTIATION,
  inschoolStages.CONTRACT_REVIEW,
  inschoolStages.CONTRACT_APPROVED,
  inschoolStages.OK_TO_DISBURSE,
];
export const stagesToShowBankBanner = [
  inschoolStages.SANCTION_GENERATED,
  inschoolStages.PF_PENDING,
  inschoolStages.PF_PAID,
  inschoolStages.EXTRA_CHARGE_COLLECTION,
  inschoolStages.POST_SANCTION_DOC_PENDING,
  inschoolStages.POST_PF_NEGOTIATION,
];
