export const Pat = {
  A: "1 - 10,00000",
  B: "10,00000 - 25,00000",
  C: "25,00000 - 50,00000",
  D: "more than 50,00000",
};

export const fileTypeAndSignatureMapper = {
  jpg: "FFD8FF",
  jpeg: "FFD8FF",
  png: "89504E47",
  pdf: "25504446",
};
