import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { imageBasePath } from "lib/utils/imageUtils";

// TODO: All these inputs are tightly couplde with react-final-form fields. Need to make them agnostic of that

export const Input = ({
  input,
  meta,
  label,
  disabled,
  placeholder,
  isPassword,
  symbol,
  ...rest
}) => {
  const hasError = meta && meta.touched && meta.error;
  const value = (input && input.value !== "") || rest.value;
  const name = (input && input.name) || rest.name;
  const maxLength = rest.maxLength || 1000;
  const [passwordHideToggle, setPasswordHideToggle] = useState(true);
  const preventKeys = ["e", "+", "-", "ArrowUp", "ArrowDown"];

  return (
    <div className="relative">
      <div className={value ? "is-filled" : ""}>
        <label
          className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto top-0 mt-2"
          htmlFor={`field-${name}`}
        >
          {label}
        </label>
        {symbol && (
          <div
            className={`flex items-center absolute right-0 top-0 mt-3 mr-5 text-gray-1150 ${
              isPassword ? "cursor-pointer" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (isPassword) {
                setPasswordHideToggle(!passwordHideToggle);
              }
            }}
          >
            {isPassword && passwordHideToggle === false ? (
              <img
                src={`${imageBasePath}/assets/icons/invisible-password.svg`}
                alt="password"
              />
            ) : (
              symbol
            )}
          </div>
        )}
        <input
          id={`field-${name}`}
          className={`h-12 px-4 pt-1 border rounded w-full text-sm text-gray-700 leading-tight placeholder-gray-500 placeholder-opacity-75 ${
            hasError ? "border-red-600" : "border-gray-300"
          }`}
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          autoFocus={rest.autoFocus}
          maxLength={maxLength}
          {...input}
          type={`${
            isPassword
              ? passwordHideToggle
                ? "password"
                : input.type
              : input.type
          }`}
          onKeyDown={(e) => {
            if (e.target.type === "number" && preventKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          onWheel={(e) => {
            e.currentTarget.blur();
            e.preventDefault();
          }}
          onBlur={(e) => {
            input.onChange(e.target.value.trim());
            input.onBlur(e);
          }}
          {...rest}
        />
      </div>
      {rest.icon && (
        <div className="absolute top-0 right-0 mr-4 mt-4">{rest.icon}</div>
      )}
      {hasError && <p className="mt-1 text-xs text-red-600">{meta.error}</p>}
      {meta.active && rest.helperText && (
        <p className="text-xs text-gray-200 mt-1">{rest.helperText}</p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .is-filled input {
          padding-top: ${label ? "1.25rem" : ""};
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      `}</style>
    </div>
  );
};

export const TextInput = ({ name, label, icon, meta, ...rest }) => {
  const hasError = meta && meta.touched && meta.error;
  return (
    <div
      className={`relative ${
        isEmpty(rest.value || rest.defaultValue) ? "" : "is-filled"
      }`}
    >
      <label
        className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto top-0 mt-2"
        htmlFor={`field-${name}`}
      >
        {label}
      </label>
      <input
        id={`field-${name}`}
        className={`h-12 px-4 pt-1 border rounded w-full text-sm text-gray-700 leading-tight placeholder-gray-500 placeholder-opacity-75 ${
          hasError ? "border-red-600" : "border-gray-300"
        }`}
        placeholder={label}
        {...rest}
        onWheel={(e) => {
          if (e) {
            e.currentTarget.blur();
          }
        }}
      />
      {icon && <div className="absolute top-0 right-0 mr-4 mt-4">{icon}</div>}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      `}</style>
    </div>
  );
};

export const InputWithAction = ({ action, onSubmit, ...rest }) => {
  return (
    <div className="relative">
      <TextInput
        onKeyPress={(e) => {
          if (e.charCode === 13) {
            e.preventDefault();
            onSubmit(rest.value);
          }
        }}
        {...rest}
      />
      <button
        className={`absolute top-0 right-0 h-7 md:h-6 px-3 rounded-sm cursor-pointer text-xs mt-2_5 md:mt-3 ${
          rest.disabled ? "text-blue" : "bg-blue-700 text-white mr-2_5 md:mr-3"
        }`}
        onClick={(e) => {
          e.preventDefault();
          onSubmit(rest.value);
        }}
      >
        {action}
      </button>
    </div>
  );
};

export const PhoneInput = ({
  input,
  meta,
  label,
  disabled,
  placeholder,
  countryCode,
  ...rest
}) => {
  const hasError = meta && meta.touched && meta.error;
  const value = (input && input.value !== "") || rest.value;
  const name = (input && input.name) || rest.name;
  const maxLength = rest.maxLength || 1000;
  const preventKeys = ["e", "+", "-", "ArrowUp", "ArrowDown"];

  return (
    <div className="relative">
      <div className={value ? "is-filled" : ""}>
        <label
          className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto top-0 mt-2"
          htmlFor={`field-${name}`}
        >
          {label}
        </label>
        <div
          className={`phone-input text-center bg-white flex items-center h-12 px-3 border rounded w-full text-sm text-gray-700 leading-tight hover:border-blue-850 ${
            hasError ? "border-red-600" : "border-gray-300"
          }`}
        >
          <p>{countryCode ? countryCode : "+91"}&nbsp;&nbsp;|&nbsp;&nbsp;</p>
          <input
            id={`field-${name}`}
            placeholder={placeholder ? placeholder : label}
            disabled={disabled}
            autoFocus={rest.autoFocus}
            maxLength={maxLength}
            {...input}
            onKeyDown={(e) => {
              if (e.target.type === "number" && preventKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onWheel={(event) => {
              event.preventDefault();
              event.target.blur();
            }}
          />
        </div>
      </div>
      {rest.icon && (
        <div className="absolute top-0 right-0 mr-4 mt-4">{rest.icon}</div>
      )}
      {hasError && !meta.active && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      {meta.active && rest.helperText && (
        <p className="text-xs text-gray-200">{rest.helperText}</p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        .phone-input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        .phone-input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .phone-input[type="number"]::-webkit-inner-spin-button,
        .phone-input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      `}</style>
    </div>
  );
};
