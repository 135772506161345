import { useState, useEffect, createRef } from "react";
import AsyncSelect from "react-select/async-creatable";
import Select from "react-select";
import Spinner from "components/common/Spinner";
import SearchIcon from "@mui/icons-material/Search";
export const MultiSelect = ({
  input,
  meta,
  label,
  placeholder,
  options,
  isSearchable,
  isDisabled,
  onChange = () => {},
  ...rest
}) => {
  const { needTopSpacing } = rest;
  const [selectMenuDirection, setSelectMenuDirection] = useState(null);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight > 0 ? window.innerHeight : screen.height,
    width: window.innerWidth > 0 ? window.innerWidth : screen.width,
  });
  const [components, setComponent] = useState(null);
  const [activeInputRef, setActiveInputRef] = useState(null);
  const [activeInputPos, setActiveInputPos] = useState(null);
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);

  const onFocusPlaceholder = (
    <span>
      <span>
        {" "}
        <SearchIcon />{" "}
      </span>
      Type to Search
    </span>
  );

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight > 0 ? window.innerHeight : screen.height,
        width: window.innerWidth > 0 ? window.innerWidth : screen.width,
      });
    };
    const handleScroll = () => {
      const element = activeInputRef && activeInputRef.current;
      const elementPosition = element && element.getBoundingClientRect().top;
      setActiveInputPos(elementPosition);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (meta.active) {
      setActiveInputRef(createRef());
    }
    return () => {
      setActiveInputRef(null);
    };
  }, [meta.active]);

  useEffect(() => {
    let elementPosition;
    if (!activeInputPos) {
      const element = activeInputRef && activeInputRef.current;
      elementPosition = element && element.getBoundingClientRect().top;
    } else {
      elementPosition = activeInputPos;
    }
    if (dimensions.width <= 1024 && activeInputRef) {
      if (elementPosition > dimensions.height / 2) {
        setSelectMenuDirection("top");
      } else {
        setSelectMenuDirection(null);
      }
    }
  }, [activeInputRef, activeInputPos, dimensions]);

  useEffect(() => {
    const components = {
      IndicatorSeparator: () => null,
    };
    setComponent(components);
  }, []);

  const backgroundColor = ({ isDisabled, isSelected, isFocused }) => {
    if (isDisabled) {
      return undefined;
    } else if (isSelected) {
      return "#42526E";
    } else if (isFocused) {
      return "#EBECF0";
    }
    return undefined;
  };

  return (
    <div
      ref={activeInputRef ? activeInputRef : null}
      className={`relative ${isDisabled ? "cursor-not-allowed" : ""}`}
    >
      <div className={input.value !== "" && "is-filled"}>
        <label
          className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto mt-2"
          htmlFor={`field-${input.name}`}
        >
          {label}
        </label>
        <Select
          id={`field-${input.name}`}
          className={`rounded border w-full relative text-sm ${meta.error &&
            meta.touched &&
            "border-red-600 bg-red-100"}`}
          components={components}
          styles={{
            control: (styles) => {
              return {
                ...styles,
                borderWidth: 0,
                background: isDisabled ? "#EFEFEF" : "white",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "0.9rem",
                paddingRight: "0.4rem",
                borderRadius: "6px",
                opacity: isDisabled ? "0.5" : "1",
                "&:hover": {
                  cursor: "pointer",
                  background: "rgb(235, 236, 240)",
                },
              };
            },
            menu: (styles) => {
              return {
                ...styles,
                top:
                  selectMenuDirection && selectMenuDirection === "top"
                    ? "auto"
                    : "",
                bottom:
                  selectMenuDirection && selectMenuDirection === "top"
                    ? needTopSpacing
                      ? "140%"
                      : "100%"
                    : "",
                zIndex: 1000,
                maxHeight:
                  selectMenuDirection && selectMenuDirection === "top" && 220,
                overflowY:
                  selectMenuDirection &&
                  selectMenuDirection === "top" &&
                  "scroll",
              };
            },
            input: (styles) => {
              return {
                ...styles,
                transform: "translate3d(-6px,9px,0)",
                paddingBottom: label ? "6px" : undefined,
              };
            },
            singleValue: (styles) => {
              return {
                ...styles,
                paddingTop: label ? "12px" : "6px",
                marginLeft: "-6px",
                color: "#565660",
              };
            },
            option: (styles, { isDisabled, isSelected, isFocused }) => {
              return {
                ...styles,
                opacity: isDisabled ? "0.5" : "1",
                cursor: isDisabled ? "not-allowed" : "default",
                backgroundColor: backgroundColor({
                  isDisabled,
                  isSelected,
                  isFocused,
                }),
              };
            },
          }}
          placeholder={inputPlaceholder}
          options={options}
          onFocus={() => {
            if (isSearchable) {
              setInputPlaceholder(onFocusPlaceholder);
            }
            input.onFocus();
          }}
          onBlur={() => {
            if (isSearchable) {
              setInputPlaceholder(placeholder);
            }
            input.onBlur();
          }}
          onChange={(v) => {
            input.onChange(v.value);
            onChange(v);
          }}
          required={rest.isRequired}
          defaultValue={options.find((a) => a.value === input.value)}
          openOuterUp={true}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          value={options.find((a) => a.value === input.value)}
        />
      </div>
      {meta.touched && meta.error && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      {meta.active && rest.helperText && (
        <p className="text-xs text-gray-200 mt-1 leading-tight">
          {rest.helperText}
        </p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          z-index: 10;
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

export const AsyncCreatableSelect = ({
  input,
  meta,
  label,
  placeholder,
  options,
  ...rest
}) => {
  return (
    <div className="relative">
      <div className={input.value !== "" ? "is-filled" : ""}>
        <label
          className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto mt-[6px]"
          htmlFor={`field-${input.name}`}
        >
          {label}
        </label>
        <AsyncCreatableSelectBase
          placeholder={placeholder}
          options={options}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onChange={(v) => {
            input.onChange(v.value);
          }}
          required={rest.isRequired}
          defaultOptions={rest.defaultOptions}
          loadingMessage={rest.loadingMessage}
          noOptionsMessage={rest.noOptionsMessage}
          loadOptions={rest.loadOptions}
          value={input.value}
          className={`rounded border w-full relative text-sm ${meta.error &&
            meta.touched &&
            "border-red-600 bg-red-100"}`}
        />
      </div>
      {meta.touched && meta.error && !meta.active && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      {rest.helperText && (
        <p className="text-xs text-gray-200 mt-1 md:hidden leading-tight">
          {rest.helperText}
        </p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          z-index: 10;
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

function AsyncCreatableSelectBase({
  value,
  defaultOptions,
  loadingMessage,
  noOptionsMessage,
  loadOptions,
  placeholder,
  onChange = () => {},
  ...rest
}) {
  const [components, setComponent] = useState(null);

  useEffect(() => {
    const LoadingIndicator = () => <Spinner size={1.2} />;
    const components = {
      IndicatorSeparator: () => null,
      LoadingIndicator,
    };
    setComponent(components);
  }, []);

  return (
    <div>
      <AsyncSelect
        cacheOptions
        components={components}
        value={value ? { label: value, value: value } : null}
        onChange={onChange}
        defaultOptions={defaultOptions}
        loadingMessage={loadingMessage}
        isValidNewOption={(option) => option.length > 0}
        noOptionsMessage={noOptionsMessage}
        createOptionPosition="last"
        formatCreateLabel={(input) => input}
        loadOptions={loadOptions}
        {...rest}
        placeholder={placeholder}
        styles={{
          control: (styles) => {
            return {
              ...styles,
              borderWidth: 0,
              background: "white",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingLeft: "1rem",
              paddingRight: "0.4rem",
              borderRadius: "6px",
            };
          },
          menu: (styles) => {
            return {
              ...styles,
              zIndex: "20",
            };
          },
          input: (styles) => {
            return {
              ...styles,
              transform: "translate3d(-6px,8px,0)",
              paddingBottom: "4px",
            };
          },
          singleValue: (styles) => {
            return {
              ...styles,
              paddingTop: "10px",
              marginLeft: "-6px",
              color: "#565660",
            };
          },
          dropdownIndicator: (styles) => {
            return {
              ...styles,
              display: "none",
            };
          },
        }}
      />
    </div>
  );
}
