import axios from "axios";

export const requestAllDisbursementsDetails = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getAllDisbursements/${applicationSlug}`,
  });

export const requestDisbursementDetails = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getDisbursementDetails/${disbursementSlug}`,
  });

export const requestCreateDisbursement = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/create/${applicationSlug}`,
  });

export const requestLoanAndDisbursementDetails = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchLoanAndDisbursementDetails/${applicationSlug}`,
  });

export const requestTransactionHistory = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/transaction_history/${applicationSlug}`,
  });

export const requestBankAccountType = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getBankAccountTypes/${applicationSlug}`,
  });

export const requestDisbursementState = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getState/${disbursementSlug}`,
  });

export const requestLeadOwner = (userPhoneNumber) =>
  axios.request({
    method: "get",
    url: `/api/route?path=application_post_sanction/${userPhoneNumber}/getLeadOwner`,
  });

export const requestOfferUpdationReason = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchUpdatedReason/${disbursementSlug}`,
  });

export const requestStudentSubmittedFundDetails = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchStudentSubmittedRequest/${disbursementSlug}`,
  });

export const requestSaveFundDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/submit",
    data,
  });

export const requestSaveDocumentDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/uploadDocument",
    data,
  });

export const requestApproveOrRejectOffer = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/acceptOrRejectOffer",
    data,
  });

export const requestSubmitStudentRejectionReason = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/submitRejectionReason",
    data,
  });

export const requestUpdateFundDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/update_request",
    data,
  });

export const requestFetchActiveDisbrusements = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchActiveDisbursements/${applicationSlug}`,
  });

export const requestSeason = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/${applicationSlug}/seasons`,
  });

export const requestDocuments = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/documents/metadata/${slug}`,
  });

export const requestProcessingFeesData = (slug) => {
  return axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/fee-status`,
  });
};
