import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplicationDetails,
  fetchBankAccountDetails,
  fetchNextStageInHistory,
  fetchRelationshipManagerDetails,
  updateApplication,
  updateBankAccountDetails,
  updateIsLoading,
  updateNextStageInHistory,
  updateRelationshipManagerDetails,
} from "components/Application/redux/ApplicationSlice";
import {
  requestApplicationDetails,
  requestBankAccountDetails,
  requestNextStageInHistory,
  requestRelationshipManagerDetails,
} from "components/Application/redux/ApplicationApi";
import { BankApplicationStages } from "constant/bankApplicationStages";

function* handleFetchApplicationDetails(action) {
  try {
    yield put(updateIsLoading(true));
    const res = yield call(requestApplicationDetails, action.payload);
    yield put(updateApplication(res?.data?.application));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* handleFetchNextStageInHistory(action) {
  try {
    const res = yield call(requestNextStageInHistory, action.payload);
    if (res?.data?.success) {
      yield put(updateNextStageInHistory(res?.data?.data?.toLowerCase()));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleFetchBankAccountDetails(action) {
  try {
    const res = yield call(requestBankAccountDetails, action.payload);
    if (res?.data?.success) {
      const bankDetails = res?.data?.data;
      yield put(
        updateBankAccountDetails({
          ...bankDetails,
          showBankBanner:
            bankDetails?.stage !== BankApplicationStages.BA_ACCOUNT_OPENED,
        })
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleFetchRelationshipManagerDetails(action) {
  try {
    const res = yield call(requestRelationshipManagerDetails, action.payload);
    if (res?.data?.success) {
      yield put(updateRelationshipManagerDetails(res?.data?.data));
    }
  } catch (err) {
    console.error(err);
  }
}

function* applicationSaga() {
  yield takeLatest(fetchApplicationDetails, handleFetchApplicationDetails);
  yield takeLatest(fetchNextStageInHistory, handleFetchNextStageInHistory);
  yield takeLatest(fetchBankAccountDetails, handleFetchBankAccountDetails);
  yield takeLatest(
    fetchRelationshipManagerDetails,
    handleFetchRelationshipManagerDetails
  );
}

export default applicationSaga;
