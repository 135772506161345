import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import axios from "axios";

export const requestApplicationDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          duration
          minimumMonthlyPayments
          minimumMonthlyPaymentDuration
          country
          cosignerAttempt
          profile {
            phone
            fathersTitle
          }
          course {
            name
            campus {
              college {
                name
              }
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestNextStageInHistory = (data) =>
  axios.post(
    "/api/route?path=in-school/application/state-change-history",
    data
  );

export const requestBankAccountDetails = () =>
  axios.get("/api/route?path=bank_account/my");

export const requestRelationshipManagerDetails = (leadPhoneNumber) =>
  axios.get(
    `/api/route?path=application_post_sanction/${leadPhoneNumber}/getLeadOwner`
  );
